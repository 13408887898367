import * as React from "react";
import { SVGProps } from "react";

export const StoreIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 30.75 30.75">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_99" data-name="Rectangle 99" width="30.75" height="30.75" fill="#4bbc9a"/>
        </clipPath>
      </defs>
      <g id="Group_38" data-name="Group 38" transform="translate(-3 1)">
        <g id="Group_37" data-name="Group 37" transform="translate(3 -1)" clipPath="url(#clip-path)">
          <path id="Path_1582" data-name="Path 1582" d="M0,.761A1.127,1.127,0,0,1,1.211,0C2.532.03,3.854.009,5.176.01c.628,0,.9.24,1.078.935q.385,1.524.771,3.047c.008.031.022.061.042.116H29.621a1.761,1.761,0,0,1,.448.03c.561.148.8.727.622,1.453q-.94,3.737-1.882,7.473-.821,3.261-1.641,6.523c-.17.674-.448.914-1.061.915-5.047,0-10.095.006-15.142-.011a.6.6,0,0,0-.642.471c-.339.852.052,1.589.876,1.59q7.421,0,14.842,0a1.776,1.776,0,0,1,.389.02,1.018,1.018,0,0,1,.728,1.095.953.953,0,0,1-.918.931c-.921.008-1.843,0-2.764,0a1.834,1.834,0,0,0-.337.021,2.807,2.807,0,0,1,1.756,1.339,3.361,3.361,0,0,1,.4,2.346,2.8,2.8,0,0,1-2.741,2.439,2.873,2.873,0,0,1-2.587-2.69A3.145,3.145,0,0,1,22.2,24.615H14.07a2.855,2.855,0,0,1,1.735,1.32,3.348,3.348,0,0,1,.425,2.34,2.811,2.811,0,0,1-2.716,2.473A2.868,2.868,0,0,1,10.9,28.057,3.16,3.16,0,0,1,13.162,24.6c-.693,0-1.321,0-1.95,0-2.182,0-3.469-2.341-2.486-4.553a1.936,1.936,0,0,0,.12-1.374Q6.771,10.521,4.736,2.353c-.022-.087-.049-.172-.084-.294H4.095c-.961,0-1.924-.025-2.884.009A1.121,1.121,0,0,1,0,1.307Z" transform="translate(0 0.001)" fill="#4bbc9a"/>
        </g>
      </g>
    </svg>
);
