import { Badge, Button, Grid, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RootState } from "../../store";
import { getUrl } from "../../utils/utils";
import { useSnackbar } from "../../hooks/snackbar";
import { updateUserAction } from "../../reducers/auth.reducer";
import clsx from "clsx";
import { useMutation } from "@tanstack/react-query";
import { update } from "../../api/user.service";
import { ChangePasswordDialog } from "./ChangePasswordDialog";
import { updateUserSchema } from "../../validation/user.validation";

type UserForm = {
  nom: string;
  prenom: string;
  tel: string;
  email: string;
  cin: string;
  adresse: string;
};

export function AccountSettings() {
  const authUser = useSelector((root: RootState) => root.auth.user!!);
  const [file, setFile] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const handleModalOpen = () => {
    setOpen(true);
  };
  const handleModalClose = () => {
    setOpen(false);
  };
  const [url, setUrl] = useState(
    !!authUser.userImage ? getUrl(authUser.userImage) : ""
  );
  const snackbar = useSnackbar();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserForm>({
    defaultValues: {
      nom: authUser.nom,
      prenom: authUser.prenom,
      cin: authUser.cin,
      tel: authUser.tel,
      email: authUser.email,
      adresse: authUser.adresse || "",
    },
    resolver: yupResolver(updateUserSchema),
  });
  const dispatch = useDispatch();
  const inputFileId = "userImage";
  const updateMutation = useMutation({
    mutationKey: ["updateUser"],
    mutationFn: (user: FormData) =>
      update(authUser.id, user)
        .then((response) => response.data)
        .then((user) => {
          localStorage.setItem("profile", JSON.stringify(user));
          dispatch(updateUserAction({ user }));
          snackbar.show("Profil modifié avec succès", "success");
        })
        .catch((e) => {
          snackbar.show("Erreur lors de la modification", "error");
        }),
  });
  const onFileChange = (e: {
    target: { name: any; value: any; files: any[any] };
  }) => {
    const file = e.target.files[0];
    if (!!file) {
      setFile(file);
      setUrl(URL.createObjectURL(file));
    }
  };

  const onSubmit = (values: UserForm) => {
    const formData = new FormData();
    formData.append("nom", values.nom);
    formData.append("prenom", values.prenom);
    formData.append("tel", values.tel);
    formData.append("email", values.email);
    formData.append("cin", values.cin);
    formData.append("adresse", values.adresse);
    file && formData.append("userImage", file);
    updateMutation.mutate(formData as any);
  };

  return (
    <div className={"container p-5"}>
      <ChangePasswordDialog isOpen={open} onClose={() => setOpen(false)} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{
                fontWeight: "bold",
                letterSpacing: 0,
                color: "#0D2646",
                fontSize: "1rem",
              }}
            >
              Paramètres du compte
            </Typography>
          </Grid>
          <Grid item xs={12} className={"mt-5"}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
                <div className={"d-block justify-content-center text-center"}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      <div>
                        <input
                          type="file"
                          id={inputFileId}
                          style={{
                            display: "none",
                          }}
                          onChange={onFileChange}
                        />
                        <Avatar
                          style={{
                            border: "3px solid white",
                            backgroundColor: "#CDE8F4",
                          }}
                          onClick={() => {
                            let input = document.getElementById(inputFileId);
                            input?.click();
                          }}
                          alt="Remy Sharp"
                          sx={{ width: 38, height: 38 }}
                        >
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#166cb5"
                              className="bi bi-camera-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                              <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                            </svg>
                          </div>
                        </Avatar>
                      </div>
                    }
                  >
                    <Avatar
                      onClick={() => {
                        let input = document.getElementById(inputFileId);
                        input?.click();
                      }}
                      alt={""}
                      src={url}
                      sx={{ width: 202, height: 202 }}
                    />
                  </Badge>
                  <p
                    className="d-flex justify-content-center text-start fw-bold mt-3"
                    style={{
                      color: "#023047",
                    }}
                  >
                    Changer Photo de Profile
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container spacing={3}>
                  <Grid item xs={6} md={6}>
                    <div className={"form-group"}>
                      <label
                        style={{
                          fontSize: "1rem",
                          letterSpacing: 0,
                          color: "#121212",
                        }}
                      >
                        Nom
                      </label>
                      <input
                        type={"text"}
                        {...register("nom")}
                        className={`form-control ${
                          errors.nom ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.nom?.message}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <div className={"form-group"}>
                      <label
                        style={{
                          fontSize: "1rem",
                          letterSpacing: 0,
                          color: "#121212",
                        }}
                      >
                        Prénom
                      </label>
                      <input
                        type={"text"}
                        {...register("prenom")}
                        className={`form-control ${
                          errors.prenom ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.prenom?.message}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div className={"form-group"}>
                      <label
                        style={{
                          fontSize: "1rem",
                          letterSpacing: 0,
                          color: "#121212",
                        }}
                      >
                        Téléphone
                      </label>
                      <input
                        {...register("tel")}
                        className={clsx(
                          "form-control",
                          !!errors.tel && "is-invalid"
                        )}
                      />
                      <div className="invalid-feedback">
                        {errors.tel?.message}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className={"form-group"}>
                      <label
                        style={{
                          fontSize: "1rem",
                          letterSpacing: 0,
                          color: "#121212",
                        }}
                      >
                        Email
                      </label>
                      <input
                        type={"email"}
                        {...register("email")}
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.email?.message}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className={"form-group"}>
                      <label
                        style={{
                          fontSize: "1rem",
                          letterSpacing: 0,
                          color: "#121212",
                        }}
                      >
                        CIN
                      </label>
                      <input
                        {...register("cin")}
                        className={`form-control ${
                          errors.cin ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.cin?.message}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className={"form-group"}>
                      <label
                        style={{
                          fontSize: "1rem",
                          letterSpacing: 0,
                          color: "#121212",
                        }}
                      >
                        Adresse
                      </label>
                      <input
                        {...register("adresse")}
                        className={`form-control ${
                          errors.adresse ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.adresse?.message}
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={
                      "d-flex justify-content-end align-items-center pr-5"
                    }
                    style={{ gap: 16 }}
                  >
                    <Button
                      type={"submit"}
                      style={{
                        backgroundColor: "black",
                        paddingLeft: 24,
                        paddingRight: 24,
                      }}
                      className={"fw-bold text-white capitalize"}
                    >
                      Sauvegarder
                    </Button>
                    <Button
                      variant={"text"}
                      className={"ml-5"}
                      style={{
                        color: "#166cb5",
                        fontSize: "1rem",
                        fontWeight: "600",
                      }}
                      onClick={handleModalOpen}
                      type="button"
                    >
                      Changer mot de passe
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
