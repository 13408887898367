import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { getList as getAllCourses } from "../../../../api/course.service";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "../../../../hooks/snackbar";
import { useNavigate } from "react-router-dom";
import { getUrl } from "../../../../utils/utils";
import { SearchInput } from "../../../../component/SearchInput";
import { Loader } from "../../../../component/Loader";
import { UpdateOneToOne } from "./UpdateOneToOne";
import { DEFAULT_PAGE, ROWS_PER_PAGE } from "../../../../utils/constants";
import { addCourse, editCourse } from "../../../../routes/admin.routes";
import { CourseActionMenu } from "./CourseActionMenu";

export function CourseListPage() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
  const [search, setSearch] = useState<undefined | string>(undefined);
  const coursesQuery = useQuery({
    queryKey: ["getCourses", search, page * rowsPerPage, rowsPerPage],
    queryFn: () => getAllCourses(search, page * rowsPerPage, rowsPerPage),
  });
  const [editCourseId, setEditCourseId] = useState<number | undefined>();
  const snackbar = useSnackbar();
  const [updateOneToOneIsOpened, openUpdateOneToOne] = useState(false);
  const navigate = useNavigate();

  const courses = coursesQuery.data?.rows || [];
  const courseToEdit = courses.find((course) => course.id === editCourseId);
  const count = coursesQuery.data?.count || 0;

  useEffect(() => {
    if (coursesQuery.error) {
      snackbar.show("Erreur du serveur", "error");
    }
  }, [coursesQuery.error]);

  useEffect(() => {
    setPage(DEFAULT_PAGE);
    setRowsPerPage(ROWS_PER_PAGE);
  }, [search]);

  return (
    <Box p={3}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <SearchInput
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Button
          style={{
            background: "#023047",
            borderRadius: 6,
            color: "white",
            paddingLeft: "3rem",
            paddingRight: "3rem",
          }}
          onClick={() => {
            navigate(addCourse);
          }}
        >
          Ajouter Formation
        </Button>
      </div>
      {coursesQuery.isLoading ? (
        <Loader
          sx={{
            minHeight: 300,
          }}
        />
      ) : (
        <Stack spacing={2}>
          <div>
            <TableContainer
              style={{
                borderRadius: 6,
              }}
            >
              <Table
                sx={{
                  border: "2px solid #E8E8E8",
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: "#FAFAFA",
                      "& th": {
                        color: "#166cb5",
                        fontWeight: "bold",
                        fontSize:"1rem",
                        padding:"4px 15px"
                      },
                    }}
                  >
                    <TableCell>Formation</TableCell>
                    <TableCell>Créer le</TableCell>
                    <TableCell>Nbre maximal des étudiants</TableCell>
                    <TableCell>Formateur</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {courses.map((course) => {
                    return (
                      <TableRow key={course.id}>
                        <TableCell>
                          <b>{course.title}</b>
                        </TableCell>
                        <TableCell>
                          {moment(course.createdAt).format("DD MMMM YYYY")}
                        </TableCell>
                        <TableCell>{course.nombre_places_disp}</TableCell>
                        <TableCell>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar
                                src={getUrl(course.formateur?.user.userImage)}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              children={course.formateur?.user.nomComplet}
                            />
                          </ListItem>
                        </TableCell>
                        <TableCell>
                          <CourseActionMenu
                            course={course}
                            editLink={editCourse(course.id)}
                            onEditOneToOne={() => {
                              setEditCourseId(course.id);
                              openUpdateOneToOne(true);
                            }}
                            onDelete={() => {
                              coursesQuery.refetch().then();
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div>
            <div>
              <TablePagination
                component={"div"}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={(event, page) => {
                  setPage(page);
                }}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                }}
              />
            </div>
          </div>
        </Stack>
      )}
      {editCourseId && courseToEdit && (
        <UpdateOneToOne
          isOpen={updateOneToOneIsOpened}
          onClose={() => {
            openUpdateOneToOne(false);
          }}
          formation={courseToEdit}
          indexFormation={courses.findIndex(
            (course) => course.id === editCourseId
          )}
          onChange={() => {
            coursesQuery.refetch();
          }}
        />
      )}
    </Box>
  );
}
