import { useSelector } from "react-redux";
import { FC, useEffect } from "react";
import { getCourses } from "../../../api/instructor.service";
import { Formation } from "../../../api/models";
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableCell } from "../../../component/StyledTableCell";
import moment from "moment";
import { format, getUrl } from "../../../utils/utils";
import { useQuery } from "@tanstack/react-query";
import { RootState } from "../../../store";
import { useSnackbar } from "../../../hooks/snackbar";
import { Empty } from "../../../component/Empty";

const CourseItem: FC<{
  course: Formation;
}> = (props) => {
  const { course } = props;

  return (
    <div style={{ marginBottom: "3rem" }}>
      <Typography style={{ fontWeight: "bold", marginBottom: "1rem" }}>
        Formation:{"  "}
        <span
          style={{
            color: "#166cb5",
          }}
        >
          {course.title}
        </span>
      </Typography>
      <TableContainer
        style={{
          borderRadius: 6,
        }}
      >
        <Table
          sx={{
            border: "2px solid #E8E8E8",
          }}
        >
          <TableHead>
            <TableRow
              style={{
                backgroundColor: "#FAFAFA",
              }}
            >
              <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
                #
              </TableCell>
              <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
                Nom et prénom
              </TableCell>
              <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
                Taux d'avancement
              </TableCell>
              <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
                Dernière connexion
              </TableCell>
              <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
                Solde One To One
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {course.etudiants.map((student, index: number) => {
              return (
                <TableRow key={student.id}>
                  <StyledTableCell>{index + 1}.</StyledTableCell>
                  <StyledTableCell>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        src={getUrl(student.user.userImage)}
                        style={{
                          marginRight: "1rem",
                        }}
                      />
                      {student.user.nomComplet}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell style={{ fontWeight: "bold" }}>
                    {student.formation_etudiant.avancement}%
                  </StyledTableCell>
                  <StyledTableCell>
                    {!!student.user.connectedAt
                      ? moment(student.user.connectedAt).format(
                          "DD MMM YYYY HH:mm"
                        )
                      : "Aucune fois"}
                  </StyledTableCell>
                  <StyledTableCell>
                    {format(student.formation_etudiant.soldeOneToOne)}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export const StudentsPage = () => {
  const userId = useSelector((state: RootState) => state.auth.user!!.id);
  const snackbar = useSnackbar();
  const query = useQuery({
    queryKey: ["coursesOfInstructor", userId],
    queryFn: () => getCourses(userId).then((response) => response.data),
  });

  useEffect(() => {
    if (query.isError) {
      snackbar.show("Erreur lors du chargement des cours", "error");
    }
  }, [query.isError]);

  if (query.isLoading) {
    return <></>;
  }

  return (
    <div style={{ padding: "2rem" }}>
      {query.data?.map((course) => (
        <CourseItem key={course.id} course={course} />
      ))}
      {query.data?.length === 0 && (
        <Empty message={"Pas d'étudiants pour le moment"} />
      )}
    </div>
  );
};
