import * as React from "react";
import { SVGProps } from "react";

export const NotificationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Notifications Button"
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    {...props}
  >
    <circle data-name="Ellipse 3" cx={20} cy={20} r={20} fill="#023047" />
    <g data-name="vuesax/linear/notification-bing">
      <path
        d="M20.026 7.262a8.616 8.616 0 0 0-8.447 8.781v2.769a7.055 7.055 0 0 1-.8 3.006l-1.611 2.8a2.849 2.849 0 0 0 1.522 4.285 28.532 28.532 0 0 0 18.682 0 2.969 2.969 0 0 0 1.522-4.285l-1.611-2.8a7.105 7.105 0 0 1-.8-3.006v-2.769a8.668 8.668 0 0 0-8.457-8.781Z"
        fill="#fff"
      />
      <path
        data-name="Vector"
        d="M24.39 28.545A4.406 4.406 0 0 1 20 32.936a4.4 4.4 0 0 1-3.099-1.291 4.4 4.4 0 0 1-1.292-3.1"
        fill="#166cb5"
      />
      <path
        data-name="Vector"
        d="M20 12.838v4.391"
        fill="none"
        stroke="#166cb5"
        strokeLinecap="round"
      />
    </g>
  </svg>
);
