import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { blackAndWhite } from "../../../styles";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useSnackbar } from "../../../hooks/snackbar";
import { useMutation, useQuery } from "@tanstack/react-query";
import { create, deleteById, getAll } from "../../../api/club.service";
import moment from "moment";
import { StyledTableCell } from "../../../component/StyledTableCell";
import { CInput } from "../../../component/CInput";
import { getUrl } from "../../../utils/utils";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Club } from "../../../api/models";

const ClubAdd: FC<{
  onAdd: () => void;
}> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [url, setUrl] = useState("");
  const form = useForm<Club>({
    resolver: yupResolver(
      yup.object().shape({
        nom: yup.string().required(),
        domaine: yup.string().required(),
      })
    ),
  });
  const snackbar = useSnackbar();
  const inputFileId = "clubFileInput";

  useEffect(() => {
    if (!!file) {
      setUrl(URL.createObjectURL(file));
    } else {
      setUrl("");
    }
  }, [file]);

  return (
    <>
      <Button
        {...blackAndWhite}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Ajouter Club
      </Button>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <form
          onSubmit={form.handleSubmit((values) => {
            create(values, file)
              .then(() => {
                form.reset();
                setFile(undefined);
                props.onAdd();
              })
              .catch(() => {
                snackbar.show("Erreur lors de l'ajout", "error");
              });
          })}
        >
          <input
            id={inputFileId}
            type={"file"}
            style={{ display: "none" }}
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (!!file) {
                setFile(file);
              }
            }}
          />
          <DialogTitle>Ajout Club</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item>
                <Box p={3}>
                  <Avatar
                    src={url}
                    style={{ width: 150, height: 150 }}
                    onClick={() => {
                      document.getElementById(inputFileId)?.click();
                    }}
                  />
                </Box>
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <CInput
                  {...form.register("nom")}
                  label={"Nom"}
                  error={!!form.formState.errors.nom?.message}
                  helperText={form.formState.errors.nom?.message}
                  fullWidth
                />
                <CInput
                  {...form.register("domaine")}
                  label={"Domaine"}
                  error={!!form.formState.errors.domaine?.message}
                  helperText={form.formState.errors.domaine?.message}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button {...blackAndWhite} type={"submit"}>
              Sauvegarder
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

const ClubDelete: FC<{
  club: Club;
  onDelete: () => void;
}> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const snackbar = useSnackbar();
  const mutation = useMutation({
    mutationKey: ["deleteClub", props.club.id],
    mutationFn: () =>
      deleteById(props.club.id).catch(() => {
        snackbar.show("Erreur lors de la suppression", "error");
      }),
  });
  return (
    <>
      <IconButton
        size={"small"}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Delete style={{ color: "crimson" }} />
      </IconButton>
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <DialogTitle>Suppression du club</DialogTitle>
        <DialogContent>
          Voulez-vous vraiment supprimer le club {props.club.nom}?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Annuler
          </Button>
          <LoadingButton
            loading={mutation.isLoading}
            {...blackAndWhite}
            onClick={() => {
              props.onDelete();
            }}
          >
            Confirmer
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ClubsPage = () => {
  const snackbar = useSnackbar();
  const query = useQuery({
    queryKey: ["get-all-clubs"],
    queryFn: () =>
      getAll()
        .then((response) => response.data)
        .catch(() => {
          snackbar.show("Erreur lors du chargement des clubs", "error");
        }),
  });

  return (
    <div>
      <Box p={3}>
        <Box marginBottom={2}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <ClubAdd
              onAdd={() => {
                query.refetch();
              }}
            />
          </div>
        </Box>
        <TableContainer
          style={{
            borderRadius: 6,
          }}
        >
          <Table
            sx={{
              border: "2px solid #E8E8E8",
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#FAFAFA",
                  "& th": {
                    color: "#166cb5",
                    fontWeight: "bold",
                    fontSize:"1rem",
                    padding:"4px 15px"
                  },
                }}
              >
                <TableCell>Nom</TableCell>
                <TableCell>Domaine</TableCell>
                <TableCell>Créer le</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {query.data?.map((club) => (
                <TableRow key={club.id}>
                  <StyledTableCell>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 8 }}
                    >
                      {club.image && (
                        <img
                          src={getUrl(club.image)}
                          style={{
                            width: 30,
                            height: 30,
                          }}
                        />
                      )}

                      {club.nom}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>{club.domaine}</StyledTableCell>
                  <StyledTableCell>
                    {moment(club.createdAt).format("DD/MM/YYYY")}
                  </StyledTableCell>
                  <StyledTableCell>
                    <ClubDelete
                      club={club}
                      onDelete={() => {
                        query.refetch();
                      }}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};
