import { Header } from "antd/lib/layout/layout";
import { Container, Grid, IconButton, Toolbar } from "@mui/material";
import { NotificationIcon } from "../../../icons/NotificationIcon";
import { ConnectedUser } from "../../../component/ConnectedUser";
import { Link } from "react-router-dom";
import { home } from "../../../routes/student.routes";

export const StudentAppBar = () => {
  return (
    <Header
      style={{
        backgroundColor: "white",
        color: "black",
        borderBottom: "1px solid #E8E8E8",
        padding: 0,
      }}
    >
      <Container maxWidth={"lg"}>
        <Toolbar
          className={"d-flex justify-content-between"}
          style={{
            flexWrap: "wrap",
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <div>
            <Link to={home}>
              <img alt={"mecadesk"} src={"/mecadesk_logo_small.png"} />
            </Link>
          </div>
          <div>
            <Grid container spacing={1} alignItems={"center"}>
              <Grid item>
                <IconButton>
                  <NotificationIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <ConnectedUser />
              </Grid>
            </Grid>
          </div>
        </Toolbar>
      </Container>
    </Header>
  );
};
