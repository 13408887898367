import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { Formation } from "../../../../../api/models";
import { useSnackbar } from "../../../../../hooks/snackbar";
import {
  addChapter,
  deleteChapter,
  updateChapter,
} from "../../../../../api/chapter.service";
import produce from "immer";
import { Button, Divider, IconButton } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import {
  addSubchapter,
  deleteSubchapter,
  updateSubchapter,
} from "../../../../../api/subchapter.service";
import { addPage, deletePage } from "../../../../../api/page.service";
import { PageSelection, QuizSelection, Selection } from "./_types";

export const SideMenu: FC<{
  courseId: string | undefined;
  selection: Selection | null;
  course: Formation | null;
  setCourse: Dispatch<SetStateAction<Formation | null>>;
  select: Dispatch<SetStateAction<Selection | null>>;
}> = (props) => {
  const { selection, course, setCourse, select } = props;
  const snackbar = useSnackbar();
  return (
    <div
      style={{
        width: "20vw",
        margin: "0.5rem",
        height: "90vh",
        overflowY: "auto",
      }}
    >
      <div>
        {course?.chapitres.map((chapter, chapterIndex) => (
          <div
            key={chapterIndex}
            style={{
              border: "2px solid #E8E8E8",
              borderRadius: 6,
              marginBottom: "2rem",
            }}
          >
            <div
              style={{
                backgroundColor: "#FAFAFA",
                borderBottom: "2px solid #E8E8E8",
                padding: "0.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  {/*<IconButton>*/}
                  {/*  <DragHandle />*/}
                  {/*</IconButton>*/}
                </div>
                <input
                  style={{
                    border: "none",
                    color: "#023047",
                    backgroundColor: "#FAFAFA",
                    fontWeight: "bold",
                    width: "100%",
                  }}
                  type={"text"}
                  value={chapter.title}
                  onBlur={(event) => {
                    updateChapter(chapter.id, {
                      title: event.target.value,
                    }).then();
                  }}
                  onChange={(e) => {
                    if (!!course) {
                      setCourse(
                        produce((course) => {
                          if (!!course) {
                            course.chapitres[chapterIndex].title =
                              e.target.value;
                          }
                        })
                      );
                    }
                  }}
                />
                <IconButton
                  size={"small"}
                  onClick={(event) => {
                    event.stopPropagation();
                    deleteChapter(chapter.id).then(() => {
                      setCourse(
                        produce((course) => {
                          if (!!course) {
                            course.chapitres = course.chapitres.filter(
                              (_chapter) => _chapter.id !== chapter.id
                            );
                          }
                        })
                      );
                    });
                  }}
                >
                  <Delete fontSize={"small"} />
                </IconButton>
              </div>
            </div>
            <div style={{ marginLeft: "1rem", padding: "0.5rem" }}>
              {chapter.souschapitres.map((subChapter, subChapterIndex) => (
                <div key={subChapterIndex}>
                  <div
                    style={{
                      padding: "0.5rem 0",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type={"text"}
                      style={{
                        border: "none",
                        width: "100%",
                        color: "#166cb5",
                        fontWeight: "bold",
                      }}
                      value={subChapter.title}
                      onBlur={(event) => {
                        updateSubchapter(subChapter.id, {
                          title: event.target.value,
                        }).catch(() => {
                          snackbar.show(
                            "Erreur lors de la mise à jour du sous chapitre",
                            "error"
                          );
                        });
                      }}
                      onChange={(e) => {
                        setCourse(
                          produce((course) => {
                            if (!!course) {
                              course.chapitres[chapterIndex].souschapitres[
                                subChapterIndex
                              ].title = e.target.value;
                            }
                          })
                        );
                      }}
                    />
                    <IconButton
                      size={"small"}
                      onClick={(event) => {
                        event.stopPropagation();
                        deleteSubchapter(subChapter.id)
                          .then(() => {
                            setCourse(
                              produce((course) => {
                                if (!!course) {
                                  course.chapitres[chapterIndex].souschapitres =
                                    course.chapitres[
                                      chapterIndex
                                    ].souschapitres.filter(
                                      (_subchapter) =>
                                        _subchapter.id !== subChapter.id
                                    );
                                }
                              })
                            );
                          })
                          .catch(() => {
                            snackbar.show(
                              "Erreur lors de la suppression du sous chapitre",
                              "error"
                            );
                          });
                      }}
                    >
                      <Delete fontSize={"small"} />
                    </IconButton>
                  </div>
                  <div style={{ marginLeft: "2rem" }}>
                    {subChapter.pages.map((page, pageIndex) => (
                      <div
                        key={pageIndex}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "0.5rem 0",
                          cursor: "pointer",
                          color: "#707070",
                          fontWeight:
                            selection?.type === "page" &&
                            selection.page === page
                              ? "bold"
                              : undefined,
                        }}
                        onClick={() => {
                          const pageSelection: PageSelection = {
                            page,
                            pageIndex,
                            subChapterIndex,
                            chapterIndex,
                            type: "page",
                          };
                          select(pageSelection);
                        }}
                      >
                        <div>Page {pageIndex + 1}</div>
                        <div>
                          <IconButton
                            size={"small"}
                            onClick={(event) => {
                              event.stopPropagation();
                              deletePage(page.id)
                                .then(() => {
                                  setCourse(
                                    produce((course) => {
                                      if (!!course) {
                                        course.chapitres[
                                          chapterIndex
                                        ].souschapitres[subChapterIndex].pages =
                                          subChapter.pages.filter(
                                            (_page) => _page.id !== page.id
                                          );
                                      }
                                    })
                                  );
                                })
                                .catch(() => {
                                  snackbar.show(
                                    "Erreur lors de la suppression de la page",
                                    "error"
                                  );
                                });
                            }}
                          >
                            <Delete fontSize={"small"} />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                    <Button
                      size={"small"}
                      style={{
                        backgroundColor: "#FFF6DF",
                        color: "#166cb5",
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                      }}
                      onClick={() => {
                        if (!!course) {
                          let title = `Page ${subChapter.pages.length + 1}`;
                          addPage(subChapter.id, `<p>${title}</p>`)
                            .then((response) => {
                              const page = response.data;
                              setCourse(
                                produce((course) => {
                                  if (!!course) {
                                    course.chapitres[
                                      chapterIndex
                                    ].souschapitres[subChapterIndex].pages.push(
                                      page
                                    );
                                  }
                                })
                              );
                            })
                            .catch(() => {
                              snackbar.show(
                                "Erreur lors de l'ajout de la page",
                                "error"
                              );
                            });
                        }
                      }}
                    >
                      <Add fontSize={"small"} />
                      Ajouter Page
                    </Button>
                    <Divider
                      style={{
                        margin: "1rem 0",
                      }}
                    />
                    <div
                      style={{
                        marginBottom: "1rem",
                        cursor: "pointer",
                        marginTop: "0.5rem",
                        color: "#707070",
                        fontWeight:
                          selection?.type === "quiz" &&
                          selection.subChapterIndex === subChapterIndex &&
                          selection.chapterIndex === chapterIndex
                            ? "bold"
                            : undefined,
                      }}
                      onClick={() => {
                        const quizSelection: QuizSelection = {
                          chapterIndex,
                          subChapterIndex,
                          type: "quiz",
                          quiz: subChapter.qcm,
                        };

                        select(quizSelection);
                      }}
                    >
                      Quiz
                    </div>
                  </div>
                </div>
              ))}
              <Button
                variant={"contained"}
                disableElevation
                size={"small"}
                style={{ backgroundColor: "rgba(122, 112, 255, 1)" }}
                onClick={() => {
                  let title = `Sous-Chapitre ${
                    chapter.souschapitres.length + 1
                  }`;
                  addSubchapter(chapter.id, title)
                    .then((response) => {
                      const subChapter = response.data;
                      if (!!course) {
                        setCourse(
                          produce((course) => {
                            if (!!course) {
                              course.chapitres[chapterIndex].souschapitres.push(
                                {
                                  ...subChapter,
                                  qcm: {
                                    ...subChapter.qcm,
                                    questions: [],
                                  },
                                  pages: [],
                                }
                              );
                            }
                          })
                        );
                      }
                    })
                    .catch(() => {
                      snackbar.show(
                        "Erreur lors de l'ajout du sous chapitre",
                        "error"
                      );
                    });
                }}
              >
                <Add fontSize={"small"} />
                Ajouter Sous-Chapitre
              </Button>
            </div>
            <div
              style={{
                backgroundColor: "rgba(205, 232, 244, 0.5)",
                paddingLeft: "1rem",
                paddingTop: "0.75rem",
                paddingBottom: "0.75rem",
              }}
            >
              <div
                style={{ color: "#023047", cursor: "pointer" }}
                onClick={() => {
                  select({
                    type: "conclusion",
                    chapterIndex,
                  });
                }}
              >
                Résumé
              </div>
              <div
                style={{ color: "#707070", cursor: "pointer" }}
                onClick={() => {
                  select({
                    type: "project",
                    chapterIndex,
                  });
                }}
              >
                Series  
              </div>
              <div
                style={{ color: "#166cb5", cursor: "pointer" }}
                onClick={() => {
                  select({
                    type: "workshop",
                    chapterIndex,
                  });
                }}
              >
                Devoirs 
              </div>
            </div>
          </div>
        ))}
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{
            color: "#023047",
            borderColor: "#707070",
            borderRadius: 6,
          }}
          variant={"outlined"}
          onClick={() => {
            const title = `Chapitre ${course!!.chapitres.length + 1}`;
            addChapter(title, Number(props.courseId))
              .then((response) => {
                setCourse(
                  produce((course) => {
                    if (!!course) {
                      course.chapitres.push({
                        ...response.data,
                        souschapitres: [],
                      });
                    }
                  })
                );
              })
              .catch(() => {
                snackbar.show("Erreur lors de l'ajout du chapitre", "error");
              });
          }}
        >
          <Add fontSize={"small"} style={{ color: "#023047" }} />
          Ajouter Chapitre
        </Button>
      </div>
    </div>
  );
};
