import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCourses } from "../../../api/instructor.service";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { RootState } from "../../../store";
import { useSnackbar } from "../../../hooks/snackbar";
import { Empty } from "../../../component/Empty";
import { CourseRow } from "./CourseRow";

export const CoursesPage = () => {
  const user = useSelector((state: RootState) => state.auth.user!!);
  const userId = user.id;
  const coursesQuery = useQuery({
    queryKey: ["coursesOfInstructor", userId],
    queryFn: () => getCourses(userId).then((response) => response.data),
  });
  const snackbar = useSnackbar();

  useEffect(() => {
    if (coursesQuery.isError) {
      snackbar.show("Erreur lors du chargement des cours", "error");
    }
  }, [coursesQuery.isError]);

  if (coursesQuery.isLoading) {
    return <></>;
  }

  return (
    <div style={{ padding: "2rem" }}>
      <div>
        {!!coursesQuery.data && coursesQuery?.data.length > 0 && (
          <TableContainer
            style={{
              borderRadius: 6,
            }}
          >
            <Table
              sx={{
                border: "2px solid #E8E8E8",
              }}
            >
              <TableHead>
                <TableRow
                  style={{
                    backgroundColor: "#FAFAFA",
                  }}
                >
                  <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
                    #
                  </TableCell>
                  <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
                    Titre
                  </TableCell>
                  <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
                    Nombre de places disp.
                  </TableCell>
                  <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
                    Prix
                  </TableCell>
                  <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
                    Durée
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {coursesQuery.data?.map((course, index: number) => {
                  return (
                    <CourseRow
                      key={course.id}
                      index={index}
                      course={course}
                      onDelete={() => {
                        coursesQuery.refetch();
                      }}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {coursesQuery.data?.length === 0 && (
          <Empty message={"Pas de cours pour le moment"} />
        )}
      </div>
    </div>
  );
};
