import { createRef, useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { EventInput } from "@fullcalendar/core";
import { EtudiantProject, OneToOne, Workshop } from "../../../api/models";
import { getCalendarEvents } from "../../../api/instructor.service";
import { useSelector } from "react-redux";
import moment from "moment";

export const CalendarPage = () => {
  // @todo: check type
  const calendarRef = useRef<any>(createRef());
  const userId = useSelector((state: any) => state.auth.user.id);
  const [workshops, setWorkshops] = useState<Workshop[]>([]);
  const [oneToOnes, setOneToOnes] = useState<OneToOne[]>([]);
  const [studentProjects, setStudentProjects] = useState<EtudiantProject[]>([]);
  const [events, setEvents] = useState<EventInput[]>([]);

  useEffect(() => {}, []);

  function handleMonthChange(dateStart: Date, dateEnd: Date) {
    getCalendarEvents(userId, dateStart, dateEnd).then((response) => {
      setWorkshops(response.data.workshops);
      // setOneToOnes(response.data.oneToOnes);
      setStudentProjects(response.data.projects);
      setEvents(() => [
        ...response.data.workshops.map((workshop) => ({
          id: `workshop_${workshop.id}`,
          title: `${workshop.title}`,
          date: moment(workshop.date).toDate(),
          backgroundColor: "#4bbc9a",
          borderColor: "#4bbc9a",
          color: "black",
          allDay: true,
        })),
        ...response.data.oneToOnes.map((oneToOne) => ({
          id: `one_to_one_${oneToOne.id}`,
          title: `One To One avec ${oneToOne.etudiant.user.nomComplet}`,
          start: moment(oneToOne.dateDebut).toDate(),
          end: moment(oneToOne.dateDebut).add(15, "minutes").toDate(),
          color: "#166cb5",
          allDay: false,
        })),
        ...response.data.projects.map((studentProject) => ({
          id: `project_${studentProject.id}`,
          title: `Projet - ${studentProject.etudiant.user.nomComplet}`,
          allDay: true,
          date: moment(studentProject.createdAt).toDate(),
          color: "#023047",
        })),
      ]);
    });
  }

  return (
    <div style={{ padding: "1rem" }}>
      <FullCalendar
        schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
        ref={calendarRef}
        initialView="dayGridMonth"
        dateClick={(...args) => {}}
        displayEventTime={true}
        weekends={true}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        selectable={true}
        plugins={[
          dayGridPlugin,
          interactionPlugin,
          timeGridPlugin,
          resourceTimeGridPlugin,
        ]}
        // datesSet={(args) => {
        //   handleMonthChange(args.start, args.end);
        // }}
        eventClick={(event) => {}}
        events={events}
        select={(args) => {}}
      />
    </div>
  );
};
