import { Avatar, Badge, Box, Button, Grid } from "@mui/material";
import { blackAndWhite } from "../../../../styles";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Formateur, User } from "../../../../api/models";
import { Container } from "@mui/system";
import { useMutation } from "@tanstack/react-query";
import { create } from "../../../../api/instructor.service";
import { useSnackbar } from "../../../../hooks/snackbar";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { CInput } from "../../../../component/CInput";

export const AddInstructorPage = () => {
  const navigate = useNavigate();
  const form = useForm<
    User & Pick<Formateur, "lienGoogleMeet" | "soldeOneToOne">
  >({
    resolver: yupResolver(
      yup.object().shape({
        nom: yup.string().required("Champs obligatoire"),
        prenom: yup.string().required("Champs obligatoire"),
        email: yup
          .string()
          .required("Champs obligatoire")
          .email("Email invalide"),
        adresse: yup.string().required("Champs obligatoire"),
        tel: yup
          .string()
          .required("Champs obligatoire")
          .matches(/[0-9]{8}/, "Ce champs doit avoir 8 caractères"),
        cin: yup
          .string()
          .required("Champs obligatoire")
          .matches(/[0-9]{8}/, "Ce champs doit avoir 8 caractères"),
        lienGoogleMeet: yup
          .string()
          .required("Ce champs est obligatoire")
          .url("Ce champs doit etre un URL"),
        soldeOneToOne: yup
          .number()
          .integer("Ce champs doit etre un nombre")
          .required("Ce champs est obligatoire")
          .min(0, "Ce champs doit etre supérieur à zéro")
          .test(
            "must-be-divisible-by-15",
            "Ce nombre doit etre divisible par 15",
            function (value) {
              return !!value && value % 15 === 0;
            }
          ),
      })
    ),
  });
  const inputFileId = "instructorImage";
  const [file, setFile] = useState<any>(null);
  const save = useMutation({
    mutationKey: ["createInstructor"],
    mutationFn: (values) =>
      create(values as any)
        .then(() => {
          form.reset();
          snackbar.show("Formateur ajouté avec succès", "success");
          setFile(null);
          setUrl("");
        })
        .catch((e) => {
          const error = e.response.data;
          switch (error) {
            case "email must be unique": {
              form.setError("email", {
                message: "Email déja utilisé",
              });
              break;
            }
            case "cin must be unique": {
              form.setError("cin", {
                message: "CIN déja utilisé",
              });
              break;
            }
            default: {
              snackbar.show(
                `Erreur lors de l'ajout: ${e.response.data}`,
                "error"
              );
            }
          }
        }),
  });
  const snackbar = useSnackbar();

  function openFileExplorer() {
    let input = document.getElementById(inputFileId);
    input?.click();
  }

  const [url, setUrl] = useState("");

  return (
    <Box p={3}>
      <Button
        {...blackAndWhite}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ChevronLeft />
        Retour
      </Button>
      <Box pt={3}>
        <form
          onSubmit={form.handleSubmit((values) => {
            const formData = new FormData();
            if (!!file) {
              formData.append("photo", file);
            }
            formData.append("nom", values.nom);
            formData.append("prenom", values.prenom);
            formData.append("cin", values.cin);
            formData.append("email", values.email);

            values.adresse && formData.append("adresse", values.adresse);
            formData.append("tel", values.tel);
            values.lienGoogleMeet &&
              formData.append("lienGoogleMeet", values.lienGoogleMeet);
            formData.append("soldeOneToOne", values.soldeOneToOne.toString());

            save.mutate(formData as any);
          })}
        >
          <Container maxWidth={"md"} component={"div"}>
            <div>
              <Grid
                container
                justifyContent={"space-between"}
                spacing={2}
                flexDirection={"row"}
              >
                <Grid item xs={12} lg={7}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <CInput
                      fullWidth
                      label={"Nom"}
                      {...form.register("nom")}
                      error={!!form.formState.errors.nom?.message}
                      helperText={form.formState.errors.nom?.message}
                    />
                    <CInput
                      fullWidth
                      label={"Prénom"}
                      {...form.register("prenom")}
                      error={!!form.formState.errors.prenom?.message}
                      helperText={form.formState.errors.prenom?.message}
                    />
                    <CInput
                      fullWidth
                      label={"Email"}
                      type={"email"}
                      {...form.register("email")}
                      error={!!form.formState.errors.email?.message}
                      helperText={form.formState.errors.email?.message}
                    />
                    <CInput
                      fullWidth
                      label={"Adresse"}
                      {...form.register("adresse")}
                      error={!!form.formState.errors.adresse?.message}
                      helperText={form.formState.errors.adresse?.message}
                    />
                    <CInput
                      fullWidth
                      label={"Télephone"}
                      type={"tel"}
                      {...form.register("tel")}
                      error={!!form.formState.errors.tel?.message}
                      helperText={form.formState.errors.tel?.message as any}
                    />
                    <CInput
                      fullWidth
                      label={"CIN"}
                      {...form.register("cin")}
                      error={!!form.formState.errors.cin?.message}
                      helperText={form.formState.errors.cin?.message}
                    />
                    <CInput
                      fullWidth
                      label={"Lien Google Meet"}
                      {...form.register("lienGoogleMeet")}
                      error={!!form.formState.errors.lienGoogleMeet?.message}
                      helperText={form.formState.errors.lienGoogleMeet?.message}
                    />
                    <CInput
                      fullWidth
                      type={"number"}
                      inputProps={{
                        step: 15,
                        min: 0,
                      }}
                      label={"Nombre des minutes one to one par semaine"}
                      {...form.register("soldeOneToOne")}
                      error={!!form.formState.errors.soldeOneToOne?.message}
                      helperText={form.formState.errors.soldeOneToOne?.message}
                    />
                  </div>
                </Grid>
                <Grid item>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      <div>
                        <input
                          type="file"
                          id={inputFileId}
                          style={{
                            display: "none",
                          }}
                          onChange={(e) => {
                            const file = e.target.files && e.target.files[0];
                            if (!!file) {
                              setUrl(URL.createObjectURL(file));
                              setFile(file);
                            }
                          }}
                        />
                        <Avatar
                          style={{
                            border: "3px solid white",
                            backgroundColor: "#CDE8F4",
                          }}
                          onClick={() => {
                            openFileExplorer();
                          }}
                          alt="camera"
                          sx={{ width: 38, height: 38 }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#166cb5"
                            className="bi bi-camera-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                            <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                          </svg>
                        </Avatar>
                      </div>
                    }
                  >
                    <Avatar
                      src={url}
                      onClick={() => {
                        let input = document.getElementById(inputFileId);
                        input?.click();
                      }}
                      alt={""}
                      sx={{ width: 202, height: 202 }}
                    />
                  </Badge>
                </Grid>
              </Grid>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <LoadingButton
                  loading={save.isLoading}
                  type={"submit"}
                  {...blackAndWhite}
                >
                  Créer
                </LoadingButton>
              </div>
            </div>
          </Container>
        </form>
      </Box>
    </Box>
  );
};
