import {
  Box,
  Button,
  Card,
  CardContent,
  CardContentProps,
  Container,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Content } from "antd/lib/layout/layout";
import { secondary, yellow } from "../../../theme/colors";
import { FC } from "react";
import { StudentAppBar } from "./StudentAppBar";
import { useNavigate } from "react-router-dom";
import {
  useAllCoursesForStudent,
  useUserToolsQuery,
} from "../../../hooks/queries";
import {
  exploreCourses,
  exploreTools,
  home,
  toolRoute,
} from "../../../routes/student.routes";
import { useDispatch } from "react-redux";
import { setCurrentCourseId } from "../../../reducers/student/actions";

const MyCard: FC<{ children: CardContentProps["children"] }> = (props) => {
  return (
    <Card
      elevation={0}
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        color: "white",
        height: "100%",
        borderRadius: 10,
      }}
    >
      <CardContent style={{ padding: "2rem 3rem", height: "100%" }}>
        {props.children}
      </CardContent>
    </Card>
  );
};

const MenuItem: FC<{
  title: string;
  onClick: () => void;
}> = (props) => {
  return (
    <ListItem
      button={true}
      sx={{
        borderRadius: "10px",
        paddingLeft: 0.5,
        paddingRight: 0.5,
        "&:hover": {
          backgroundColor: secondary,
        },
        "&:hover .circle": {
          backgroundColor: yellow,
        },
        "&:hover .text": {
          color: yellow,
        },
      }}
      onClick={props.onClick}
    >
      <ListItemIcon style={{ minWidth: 22 }}>
        <Box
          className={"circle"}
          sx={{
            width: 14,
            height: 14,
            borderRadius: "50%",
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: yellow,
            },
          }}
        />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          className: "text",
          sx: {
            fontWeight: "bold",
            "&:hover": {
              color: yellow,
            },
          },
        }}
        primary={props.title}
      />
    </ListItem>
  );
};

export const StudentHome = () => {
  const navigate = useNavigate();
  const coursesQuery = useAllCoursesForStudent();
  const userToolsQuery = useUserToolsQuery();
  const dispatch = useDispatch();
  const tools = userToolsQuery.data || [];
  const courses = coursesQuery.data || [];

  return (
    <div>
      <StudentAppBar />
      <Content>
        <div
          style={{
            background:
              "#166cb5",
            backgroundSize: "cover",
            minHeight: "calc(100vh - 64px)",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              minHeight: "calc(100vh - 64px)",
            }}
          >
            <div style={{ padding: "3rem 0" }}>
              <Container maxWidth={"lg"}>
                <div>
                  <Container maxWidth={"md"}>
                    <Grid
                      container
                      spacing={4}
                      style={{ paddingBottom: "5rem" }}
                    >
                      <Grid item xs={12} lg={6}>
                        <MyCard>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "1rem",
                              alignItems: "center",
                              justifyContent: "space-between",
                              height: "100%",
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: "Biennale",
                                fontWeight: 950,
                                fontSize: 32,
                                textAlign: "center",
                              }}
                            >
                              Reprendre
                              <br />
                              Votre
                              <br />
                              Formation
                            </Typography>
                            <Box style={{ width: "100%" }}>
                              {courses
                                .filter((course) => course.inMyList)
                                .map((course) => (
                                  <MenuItem
                                    key={course.id}
                                    title={course.title}
                                    onClick={() => {
                                      dispatch(setCurrentCourseId(course.id));
                                      navigate(home);
                                    }}
                                  />
                                ))}
                            </Box>
                            <Button
                              fullWidth
                              variant={"contained"}
                              style={{
                                backgroundColor: "black",
                                color: "white",
                              }}
                              onClick={() => {
                                navigate(exploreCourses);
                              }}
                            >
                              Parcourir les formations
                            </Button>
                          </div>
                        </MyCard>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <MyCard>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "1rem",
                              alignItems: "center",
                              justifyContent: "space-between",
                              height: "100%",
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: "Biennale",
                                fontWeight: 950,
                                fontSize: 32,
                                textAlign: "center",
                              }}
                            >
                              Outils de Calcul
                            </Typography>
                            <div>
                              <img src={"/tools_icon.svg"} />
                            </div>
                            <Box style={{ width: "100%" }}>
                              {tools.map((tool) => (
                                <MenuItem
                                  key={tool.id}
                                  title={tool.title}
                                  onClick={() => {
                                    navigate(toolRoute(tool.id));
                                  }}
                                />
                              ))}
                            </Box>
                            <Button
                              fullWidth
                              variant={"contained"}
                              style={{
                                backgroundColor: "black",
                                color: "white",
                              }}
                              onClick={() => {
                                navigate(exploreTools);
                              }}
                            >
                              Parcourir les outils
                            </Button>
                          </div>
                        </MyCard>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
                <img src={"/mecadesk_logo_white.png"} style={{
                                margin: "auto",
                                
                              }} />
              </Container>
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
};
