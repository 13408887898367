import React, { FC, useEffect, useRef, useState } from "react";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledTableCell } from "../workshop/Workshop";
import { getAllOneToOneByEtudiant } from "../../../api";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { OneToOneBalance } from "../../../component/StudentOneToOneBalance";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { RootState } from "../../../store";
import { getOneToOnes } from "../../../api/student.service";
import { OneToOne } from "../../../api/models";
import { useSnackbar } from "../../../hooks/snackbar";
import { calendar } from "../../../routes/student.routes";

export const OneToOneTable: FC<{
  oneToOnes: OneToOne[];
}> = (props) => {
  return (
    <TableContainer
      style={{
        borderRadius: 6,
      }}
    >
      <Table
        sx={{
          border: "2px solid #E8E8E8",
        }}
      >
        <TableHead>
          <TableRow
            style={{
              backgroundColor: "#FAFAFA",
            }}
          >
            <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
              #
            </TableCell>
            <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
              One To One
            </TableCell>
            <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
              Date Et Heure
            </TableCell>
            <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
              Lien
            </TableCell>
            <TableCell style={{ color: "#166cb5", fontWeight: "bold" }}>
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.oneToOnes.map((oneToOne, index) => {
            return (
              <TableRow key={oneToOne.id}>
                <StyledTableCell>{index + 1}.</StyledTableCell>
                <StyledTableCell>
                  One To One avec <b>{oneToOne?.formateur?.user.nomComplet}</b>
                </StyledTableCell>
                <StyledTableCell style={{ fontWeight: "bold" }}>
                  {moment(oneToOne.dateDebut).format("DD MMMM YYYY - HH:mm")}
                </StyledTableCell>
                <StyledTableCell>
                  <a
                    href={oneToOne.formateur.lienGoogleMeet}
                    target={"__blank"}
                  >
                    {oneToOne.formateur.lienGoogleMeet}
                  </a>
                </StyledTableCell>
                <StyledTableCell>
                  <span
                    style={{
                      color:
                        new Date(oneToOne.dateDebut) < new Date()
                          ? "rgb(255, 183, 3)"
                          : "gray",
                    }}
                  >
                    {new Date(oneToOne.dateDebut) < new Date()
                      ? "Terminé"
                      : "À Venir"}
                  </span>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export function OneToOnePage() {
  const auth = useSelector((state: RootState) => state.auth);
  const currentCourseId = useSelector(
    (state: RootState) => state.student.currentCourseId
  );
  const snackbar = useSnackbar();
  const query = useQuery({
    enabled: !!currentCourseId,
    queryKey: ["getOneToOneStudent", [auth.user!!.id, currentCourseId]],
    queryFn: () =>
      getOneToOnes(auth.user!!.id, {
        courseId: currentCourseId,
      })
        .then((response) => response.data)
        .catch(() => {
          snackbar.show("Erreur lors du chargement des one to one", "error");
        }),
  });
  const location = useLocation();
  const [OneToOneData, setOneToOneData] = useState([]);

  const loadOnce = useRef(true);

  useEffect(() => {
    getAllOneToOneByEtudiant({
      userId: auth.user?.id,
      idFormation: currentCourseId,
    }).then((data: any) => {
      setOneToOneData(data.data);
    });
  }, [location.pathname, currentCourseId]);

  const navigate = useNavigate();

  return (
    <div className={"m-5"}>
      <Grid container justifyContent={"space-between"} spacing={4}>
        <Grid item xs={12}>
          <div>
            <OneToOneBalance />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant={"contained"}
            style={{
              textTransform: "capitalize",

              backgroundColor: "#121212",
              color: "white",
            }}
            className={"mt-3 btn "}
            onClick={() => {
              navigate(calendar);
            }}
          >
            <span
              style={{
                fontSize: ".95rem",
              }}
              className={"mb-0"}
            >
              Demander un one to one
            </span>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <OneToOneTable oneToOnes={query.data || []} />
        </Grid>
      </Grid>
    </div>
  );
}
