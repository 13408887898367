import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../store";

export const InstructorCurrentCourse = () => {
  const currentCourse = useSelector(
    (state: RootState) => state.instructor.currentCourse
  );

  if (!currentCourse) {
    return <></>;
  }

  return (
    <Typography className={"fw-bold"} style={{ color: "#023047" }}>
      Formation:{" "}
      <span
        style={{
          color: "#166cb5",
        }}
      >
        {currentCourse.title}
      </span>
    </Typography>
  );
};
